import React, { useContext, useEffect, useRef, useState } from 'react';
import './DashboardStake.css';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Button2 from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { USDStakeContext } from '../../../../Contexts/USDContext';
import swal from 'sweetalert';
import { toast } from 'react-hot-toast';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);
const DashboardStake = () => {
    const { logOut, user, openWalletModal, metamaskBalance, payUSDT, level, roi, prcntge, getBalanceTestnet } = useContext(USDStakeContext);
    // let storage = sessionStorage.getItem("affiliate");
    const [affiliateInput, setAffiliateInput] = useState("");
    const [inputValue, setInputValue] = useState(null);
    const stakeAmount = useRef(null);

    // const handleChange = (e) => {
    //     let value = Number(inputStake.current.value);
    //     // console.log(value);
    //     console.log(inputStake.current.value);
    //     if (value != '' && value % 50 === 0) {
    //         setInputValue(inputStake.current.value);
    //     }
    // };

    const handleInputChange = (event) => {
        const { value } = event.target;

        // Remove non-digit characters from the value
        const numericValue = value.replace(/\D/g, '');

        setInputValue(() => numericValue);
    };
    const handleInputStakeChange = (event) => {
        const { value } = event.target;
        // console.log(value);
        if (value % 5000 === 0) {
            let checkSum = user?.totalInvested + Number(value);
            let checkSumV2 = user?.totalInvestedV2 + Number(value);
            if (checkSum > 200000 || checkSumV2 > 200000) {
                toast.dismiss();
                toast.error("UNITIC Staking Limit Exceeded");
                setInputValue(() => 0);
            }
            else {

                setInputValue(() => value);
            }
        }
        else {
            toast.error("Please Enter Valid amount (Multiple of 5000)");
            setInputValue(0);
        }

    };

    useEffect(() => {
        if (sessionStorage.getItem("affiliate")) {
            setAffiliateInput(() => sessionStorage.getItem("affiliate")?.split('com/')[1]);
        }
        getBalanceTestnet();
    }, []);

    const LogOut = () => {
        logOut();
        swal({
            title: "Success",
            text: "You have succesfully logged out.",
            icon: "success",
            button: "OK",
            className: "modal_class_success",
        });
    }

    const staking = () => {
        let val = Number(inputValue);
        if (val % 5000 !== 0 || val <= 0) {
            // console.log("NO", val);
            return;
        }
        else {
            if (affiliateInput == user?.walletAddress) {
                toast.error("Self Referral Address Not Allowed");
            }
            else {

                payUSDT(Number(inputValue), affiliateInput);
                // console.log("YES", val);
            }
        }

    }
    return (
        <section className="bg-stakeBg min-vh-100">
            <div className='container mb-5' style={{ paddingTop: "250px" }}>
                <div className='d-flex justify-content-center align-items-center flex-column flex-sm-column flex-lg-row' style={{ gap: "15px" }}>
                    <div>
                        <h3 className='mb-0 stakeTextColor'>Total ROI <span className="text-grad">Upto 200 %.</span> Refer To Increase Your Tiers & Daily ROI <span className="text-grad">Upto 0.5%.</span></h3>
                        <p className="staking-text">Start Staking Now..!!</p>
                    </div>
                    <div >
                        <Card className='rounded p-2 sizeOfCard'>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} gutterBottom className='fw-bold text-dark'>
                                    Wallet Balance (UNITIC)
                                </Typography>
                                <Typography variant="h5" component="div" className='mb-3' >
                                    <input type="text" className='w-100 border-0 p-2 fs-6' style={{ background: '#E2EFF8', borderRadius: "9px", outline: "0px" }} value={metamaskBalance?.usdt} readOnly />
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} gutterBottom className='fw-bold text-dark'>
                                    Amount to stake (UNITIC)
                                </Typography>
                                <Typography variant="h5" component="div" className='mb-3'>
                                    <input type="text" className='w-100 border-0 p-2 fs-6' style={{ background: '#E2EFF8', borderRadius: "9px", outline: "0px" }} value={inputValue} onBlur={(e) => handleInputStakeChange(e)} onChange={handleInputChange} />
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} gutterBottom className='fw-bold text-dark '>
                                    Affiliate Address
                                </Typography>
                                <Typography variant="h5" component="div">
                                    <input type="text" className='w-100 border-0 p-2 fs-6' style={{ background: '#E2EFF8', borderRadius: "9px", outline: "0px" }} value={affiliateInput} onChange={(e) => setAffiliateInput(e.target.value)} />
                                </Typography>

                                <Typography variant="div">
                                    <div className='row gy-5 py-3' >

                                        <div className='col-6 col-sm-6 col-md-3  border-end border-2 border-dark'>
                                            <div className='d-flex justify-content-center align-items-center flex-column' style={{ gap: "7px" }}>
                                                <small className='mb-0'>Your Tier</small>
                                                <p className='mb-0 fw-bold'>{level} Tier</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-3  border-end border-2 border-dark'>
                                            <div className='d-flex justify-content-center align-items-center flex-column' style={{ gap: "7px" }}>
                                                <small className='mb-0'>Daily ROI</small>
                                                <p className='mb-0 fw-bold'>{roi}%</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-3  border-end border-2 border-dark'>
                                            <div className='d-flex justify-content-center align-items-center flex-column' style={{ gap: "7px" }}>
                                                <small className='mb-0'>Monthly ROI</small>
                                                <p className='mb-0 fw-bold'>{(30 * roi).toFixed(2)}%</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-3  border-0'>
                                            <div className='d-flex justify-content-center align-items-center flex-column' style={{ gap: "7px" }}>
                                                <small className='mb-0'>Yearly ROI</small>
                                                <p className='mb-0 fw-bold'>{(365 * roi).toFixed(2)}%</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='mx-auto text-center my-2'>
                                        {
                                            (!user?.walletAddress || user?.walletAddress === undefined || user?.walletAddress === "undefined") ?
                                                <Button2 variant="success" className="connectWallet" onClick={() => openWalletModal()}>Connect Wallet</Button2> : <Button2 className="connectWallet" variant="success" onClick={() => staking()}>STAKE</Button2>
                                        }
                                    </div>
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div >
        </section>
    );
};

export default DashboardStake;