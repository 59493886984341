import axios from "axios";
import React, { useEffect, useState, createContext } from "react";
import { ethers, BigNumber } from "ethers";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
// import abi from "../utils/nftAbi.json";
import {
    USDTtokenAddressTestnet,
    USDTtokenABITest,
    arbitrumAddress,
    ArbitrumABI,
    SmartcontractAddress,
    SmartcontractABI,
    SmartcontractAddressarbitrum,
    SmartcontractarbitrumABI,

} from "../utils/constant";
import Swal from "sweetalert2";

export const USDStakeContext = createContext();

const { ethereum } = window;

const getSmartContractTestnet = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const SmartUsdtContract = new ethers.Contract(
        SmartcontractAddress,
        SmartcontractABI,
        signer
    );

    return SmartUsdtContract;
};

const getUSDtokenContractTestnet = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
        USDTtokenAddressTestnet,
        USDTtokenABITest,
        signer
    );

    return tokenContract;
};
const getSmartContractArbitrum = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const SmartArbitrumContract = new ethers.Contract(
        SmartcontractAddressarbitrum,
        SmartcontractarbitrumABI,
        signer
    );

    return SmartArbitrumContract;
};

const getArbitrumTokenContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
        arbitrumAddress,
        ArbitrumABI,
        signer
    );

    return tokenContract;
};


const getAllItemBlockchain = async () => {
    // const provider = new ethers.providers.JsonRpcProvider(RPC);
    // return {
    //     provider,
    //     deployer: new ethers.Wallet(private_key, provider),
    //     NFTContract: new Contract(mintAddressTestnet, abi, provider),
    // };
};

const genSignature = async (types, voucher, auth) => {
    const domain = {
        name: "NFT-Voucher",
        version: "1",
        verifyingContract: auth.contract,
        // chainId: chainId,
    };
    const BuyNFTVoucher = {
        id: voucher.id,
        price: voucher.price,
        tokenAddress: voucher.tokenAddress,
        nonce: voucher.nonce,
    };

    // const signature = await auth.signer._signTypedData(domain, types, BuyNFTVoucher);

    return {
        ...voucher,
        // signature,
    };
};

const signBuyFunction = async (id, price, tokenAddress, refAddress, uri) => {
    const contracts = await getAllItemBlockchain();
    const auth = {
        signer: contracts.deployer,
        contract: contracts.NFTContract.address,
    };

    const types = {
        BuyNFTStruct: [
            { name: "id", type: "string" },
            { name: "price", type: "uint256" },
            { name: "tokenAddress", type: "address" },
            { name: "nonce", type: "string" },
        ],
    };
    // console.log("111111111111111: ", id, price, tokenAddress, refAddress, uri);

    // Generate nonce as transaction id
    const nonce = uuidv4();
    const voucher = {
        id: id,
        // price: BigNumber.from(price),
        tokenAddress: tokenAddress,
        refAddress:
            refAddress.length !== 0
                ? refAddress
                : "0x0000000000000000000000000000000000000000",
        nonce: nonce,
        uri: uri,
    };
    return {
        ...(await genSignature(types, voucher, auth)),
        price: price.toString(),
    };
};

export default function USDStakeProvider({ children }) {
    const [loginModal, setLoginModal] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const [walletModal, setWalletModal] = useState(false);
    const [walletWarning, setWalletWarning] = useState(false);
    const [Id, setId] = useState();
    const [chain, setChain] = useState("");
    const [payAmount, setPayAmount] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [metamaskBalance, setMetamaskBalance] = useState({});
    const [metamaskBalanceLoading, setMetamaskBalanceLoading] = useState(false);
    const [coinbaseModal, setCoinbaseModal] = useState(false);
    const [userRefetch, setUserRefetch] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [goToProfile, setGoToProfile] = useState(false);
    const [destination, setDistination] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [level, setLevel] = useState("1st");
    const [roi, setRoi] = useState(2);
    const [prcntge, setPrcntge] = useState(0.999);
    const [withdrawLoading, setWithdrawLoading] = useState(false);
    const [contractData, setContractData] = useState('');
    const [contractArbitrumData, setContractArbitrumData] = useState('');
    const [stakeListRefetch, setStakeListRefetch] = useState(false);
    const [walletLoading, setWalletLoading] = useState(true);

    const fetchData = async () => {
        try {
            // Connect to the Ethereum network
            const provider = new ethers.providers.Web3Provider(window.ethereum);

            // Load the smart contract's ABI and address
            const contractABI = SmartcontractABI; // ABI of your smart contract
            const contractAddress = SmartcontractAddress; // Address of your smart contract

            // Instantiate the contract
            const contract = new ethers.Contract(contractAddress, contractABI, provider);
            // console.log(contract);
            // Call a contract function or read a state variable
            const data = await contract.functions.withdraw(); // Replace 'myFunction' with the actual function name

            // Update the component state with the fetched data
            setContractData(() => data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchDataArbitrum = async () => {
        try {
            // Connect to the Ethereum network
            const provider = new ethers.providers.Web3Provider(window.ethereum);

            // Load the smart contract's ABI and address
            const contractABI = SmartcontractarbitrumABI; // ABI of your smart contract
            const contractAddress = SmartcontractAddressarbitrum; // Address of your smart contract

            // Instantiate the contract
            const contract = new ethers.Contract(contractAddress, contractABI, provider);
            // console.log(contract);
            // Call a contract function or read a state variable
            const data = await contract.functions.withdraw(); // Replace 'myFunction' with the actual function name

            // Update the component state with the fetched data
            setContractData(() => data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if (chain == '0xa4b1') {
            fetchDataArbitrum()
        }
        else if (chain == '0x89') {
            fetchData();
        }
    }, [chain]);
    // console.log("Testing", contractData);
    window.addEventListener("load", () => {
        setPageLoading(false);
    });

    const openWalletModal = () => {
        (!user?.walletAddress ||
            user?.walletAddress === "walletAddress undefined") &&
            setAnchorEl(null);
        setWalletModal(true);
    };
    const closeWalletModal = () => {
        setAnchorEl(null);
        setWalletModal(false);
    }
    const openWalletModalWarning = () => {
        (!user?.walletAddress ||
            user?.walletAddress === "walletAddress undefined") &&
            setAnchorEl(null);
        setWalletWarning(true);
    };
    const closeWalletModalWarning = () => {
        setAnchorEl(null);
        setWalletWarning(false);
    }

    const openCoinbaseModal = () => {
        // (!user?.walletAddress || user?.walletAddress === "undefined") &&
        setCoinbaseModal(true);
    };
    const closeCoinbaseModal = () => setCoinbaseModal(false);

    const openLoginModal = () => setLoginModal(true);
    const closeLoginModal = () => setLoginModal(false);

    useEffect(() => {
        checkIfWalletIsConnect();
    }, []);

    const getBalanceTestnet = async (defChain = chain) => {
        let tokenContract;

        if (defChain == '0xa4b1') {
            tokenContract = getArbitrumTokenContract();
        }
        else if (defChain == '0x89') {
            tokenContract = getUSDtokenContractTestnet();
        }
        const USDbalance = await tokenContract?.balanceOf(currentAccount);
        const USDamount = ethers.utils.formatEther(USDbalance);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const balance1 = await provider.getBalance(currentAccount);
        // console.log("usdt: " + USDamount);
        // console.log("BNB: " + ethers.utils.formatEther(balance1));
        const wallet = {
            usdt: USDamount,
            bnb: ethers.utils.formatEther(balance1),
        };
        return setMetamaskBalance(wallet);
    };
    // const getBalanceArbitrumTestnet = async () => {
    //     const USDtokenContract = getArbitrumTokenContract();
    //     const USDbalance = await USDtokenContract.balanceOf(currentAccount);
    //     const USDamount = ethers.utils.formatEther(USDbalance);
    //     const provider = new ethers.providers.Web3Provider(ethereum);
    //     const balance1 = await provider.getBalance(currentAccount);
    //     console.log("usdt: " + USDamount);
    //     console.log("BNB: " + ethers.utils.formatEther(balance1));
    //     const wallet = {
    //         usdt: USDamount,
    //         bnb: ethers.utils.formatEther(balance1),
    //     };
    //     return setMetamaskBalance(wallet);
    // };

    useEffect(() => {
        getBalanceTestnet();
    }, []);


    // window.addEventListener("load", function () {
    //     if (window.ethereum) {
    //         // detect Metamask account change
    //         window.ethereum.on("accountsChanged", function (accounts) {
    //             console.log("account is Changed", accounts);
    //             // logOut();
    //             // return swal({
    //             //   title: "Attention",
    //             //   text: "You are being logged out since you changed account. Please login again with the account you need.",
    //             //   icon: "warning",
    //             //   button: "OK",
    //             //   dangerMode: true,
    //             //   className: "modal_class",
    //             // });
    //         });

    //         // detect Network account change
    //         window.ethereum.on("networkChanged", function (networkId) {
    //             console.log("network is changed: ", networkId);
    //             // logOut();
    //             // return swal({
    //             //   title: "Attention",
    //             //   text: "You are being logged out since you Changed network. Please login after changing to Polygon Chain.",
    //             //   icon: "warning",
    //             //   button: "OK",
    //             //   dangerMode: true,
    //             //   className: "modal_class",

    //             // });
    //         });
    //     } else {
    //         throw new Error("No ethereum object");
    //     }
    // });

    const logOut = async () => {
        setCurrentAccount(null);
        setUser({});
        localStorage.removeItem("userOfutistake");
    };

    const checkIfWalletIsConnect = async () => {
        try {
            if (!ethereum) {
                return console.log("please use metamask");
            }

            const accounts = await ethereum.request({ method: "eth_accounts" });

            if (accounts.length) {
                setCurrentAccount(() => accounts[0]);
                const chainid = await window.ethereum.request({
                    method: "eth_chainId",
                });
                setChain(() => chainid);
            } else {
                console.log("No accounts found");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     if (user.walletAddress) {
    //         getBalance();
    //     }
    // }, [user])

    const connectWallet = async (wallet) => {
        try {
            // console.log("connect");
            if (window.innerWidth < 576 && !ethereum) {
                return swal({
                    title: "Attention",
                    text: "Please use Metamask browser!",
                    icon: "warning",
                    button: "OK",
                    dangerMode: true,
                    className: "modal_class",
                });
            }
            if (!ethereum) {
                return console.log("please use metamask");
            }
            if (wallet === "Metamask") {
                setLoading(true);

                const chainid = await window.ethereum.request({
                    method: "eth_chainId",
                });
                // console.log("This is Chain ID: ", chainid);
                setChain(() => chainid);
                if (chainid === "0x89") {
                    const accounts = await ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    setCurrentAccount(() => accounts[0]);
                    // 
                    await axios
                        .post(`https://api.utistaking.com/api/v1/wallet-user/`, {
                            walletAddress: accounts[0],
                        })
                        .then((res) => {
                            if (res.data.user) {
                                getBalanceTestnet(chainid);
                                setUser(res.data.user);
                                setLoading(false);
                                closeWalletModal();
                                localStorage.setItem("userOfutistake", res.data.token);
                                const wrapper = document.createElement("div");
                                wrapper.innerHTML = `<p class='text-break text-white fs-6'>You have successfully logged in with <br/>Polygon Chain.</p>`;
                                return swal({
                                    // title: "Success",
                                    // text: "You have succesfully logged in with Polygon Chain.",
                                    content: wrapper,
                                    icon: "success",
                                    button: "OK",
                                    // dangerMode: true,
                                    className: "modal_class_success",
                                });
                            }
                        });
                } else {
                    swal({
                        title: "Attention",
                        text: "Please change to Polygon Chain before connecting.",
                        icon: "warning",
                        button: "OK",
                        dangerMode: true,
                        className: "modal_class",
                    });
                }
            }
        } catch (error) {
            console.log(error);
            throw new Error("No ethereum object");
        }
    };

    // async function getAccount() {
    //     const provider = new ethers.providers.Web3Provider(web3.currentProvider);
    //     const accounts = await provider.listAccounts();
    //     return accounts[0];
    // }

    // const account = getAccount();

    // async function transferusdt(amount) {
    //     try {
    //       const provider = new ethers.providers.Web3Provider(web3.currentProvider);
    //       const signer = provider.getSigner();
    //       const contract = new ethers.Contract(SmartcontractAddress.address, SmartcontractABI.abi, signer);

    //       const result = await contract.transfer(amount, { from: account });
    //       console.log(result);
    //       setSuccess(`Successfully transfered ${amount} USDT.`);
    //     } catch (err) {
    //       setError(err.message);
    //     }
    //   }


    const payUSDT = async (amount, affiliates) => {
        try {
            if (ethereum) {
                if (chain == '0xa4b1') {
                    return payUSDTArbirum(amount, affiliates)
                }

                else {
                    let checkSum = user?.totalInvested + amount;
                    let checkSumV2 = user?.totalInvestedV2 + amount;
                    if (checkSum > 200000 || checkSumV2 > 200000) {
                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = `<p class='text-break text-dark fs-5'>UTI Staking Limit Exceeded!</p>`;
                        return swal({
                            title: "Attention",
                            content: wrapper,
                            icon: "warning",
                            button: "OK",
                            // dangerMode: true,
                            className: "modal_class_success",
                        });

                    }
                    setRequestLoading(true);
                    const PGContract = getSmartContractTestnet();
                    const UsdtTokenContract = getUSDtokenContractTestnet();
                    const provider = new ethers.providers.Web3Provider(ethereum);

                    // console.log(
                    //     "USDT",
                    //     PGContract.address,chain,
                    //     BigNumber.from(ethers.constants.MaxUint256)
                    // );

                    const payment = await UsdtTokenContract.approve(
                        PGContract.address,
                        BigNumber.from(ethers.constants.MaxUint256)
                    );
                    let payment_test = await provider.getTransaction(payment.hash);
                    while (payment_test.blockNumber === null) {
                        // console.log("Approve In Progress...");
                        payment_test = await provider.getTransaction(payment.hash);
                    }
                    // console.log(payment_test.blockNumber);
                    let payment_hash = "https://polygonscan.com/tx/" + payment.hash;
                    // console.log("Payment link: " + payment_hash);

                    const a = ethers.utils.parseEther(amount.toString());
                    let affiliate = affiliates ? affiliates : "0x0000000000000000000000000000000000000000";
                    // console.log(typeof affiliate, affiliate);
                    const Val = await PGContract.transfer(
                        "0xaE6e203ca4Ed0Fa0Eee96A797da670ea1D62CE95",
                        affiliate,
                        a._hex
                    );

                    await Val.wait();
                    let finalPayment = "https://polygonscan.com/tx/" + Val.hash;
                    let txn_test = await provider.getTransaction(Val.hash);
                    if (txn_test) {
                        while (txn_test.blockNumber === null) {
                            // console.log("Processing...");
                            txn_test = await provider.getTransaction(Val.hash);
                        }
                        // console.log("txn_test.blockNumber: " + txn_test.blockNumber);
                        await savingInvestInfo(amount, finalPayment, affiliates);

                    }
                }

            }
        }

        catch (error) {
            console.log(error);
            setRequestLoading(false);
            console.log("No ethereum object");
            //setRequestLoading(false);
            if (error.code === -32603) {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-dark fs-5'>Insufficient funds for transfer!</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // Swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            } else {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-dark fs-5'>Transfer Failed</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            }
            throw new Error("No ethereum object");
        }
    };
    const payUSDTArbirum = async (amount, affiliates) => {
        try {
            // console.log("amount and affiliate", amount, affiliates);
            if (ethereum) {
                let checkSum = user?.totalInvested + amount;
                let checkSumV2 = user?.totalInvestedV2 + amount;
                if (checkSum > 200000 || checkSumV2 > 200000) {
                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<p class='text-break text-dark fs-5'>UTI Staking Limit Exceeded!</p>`;
                    return swal({
                        title: "Attention",
                        content: wrapper,
                        icon: "warning",
                        button: "OK",
                        // dangerMode: true,
                        className: "modal_class_success",
                    });

                }
                setRequestLoading(true);
                const PGContract = getSmartContractArbitrum();
                const UsdtTokenContract = getArbitrumTokenContract();
                const provider = new ethers.providers.Web3Provider(ethereum);

                // console.log(
                //     "USDT",
                //     PGContract.address,chain,
                //     BigNumber.from(ethers.constants.MaxUint256)
                // );

                const payment = await UsdtTokenContract.approve(
                    PGContract.address,
                    BigNumber.from(ethers.constants.MaxUint256)
                );
                let payment_test = await provider.getTransaction(payment.hash);
                while (payment_test.blockNumber === null) {
                    // console.log("Approve In Progress...");
                    payment_test = await provider.getTransaction(payment.hash);
                }
                // console.log(payment_test.blockNumber);
                let payment_hash = "https://arbiscan.io/tx/" + payment.hash;
                // console.log("Payment link: " + payment_hash);

                const a = ethers.utils.parseEther(amount.toString());
                let affiliate = affiliates ? affiliates : "0x0000000000000000000000000000000000000000";
                // console.log(typeof affiliate, affiliate);
                const Val = await PGContract.transfer(
                    "0x1D4783444F529614DADA51C063024496bF50f51c",
                    affiliate,
                    a._hex
                );

                await Val.wait();
                let finalPayment = "https://arbiscan.io/tx/" + Val.hash;
                let txn_test = await provider.getTransaction(Val.hash);
                if (txn_test) {
                    while (txn_test.blockNumber === null) {
                        // console.log("Processing...");
                        txn_test = await provider.getTransaction(Val.hash);
                    }
                    // console.log("txn_test.blockNumber: " + txn_test.blockNumber);
                    await savingInvestInfo(amount, finalPayment, affiliates);

                }

            }
        }

        catch (error) {
            console.log(error);
            setRequestLoading(false);
            console.log("No ethereum object");
            //setRequestLoading(false);
            if (error.code === -32603) {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-dark fs-5'>Insufficient funds for transfer!</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // Swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            } else {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-dark fs-5'>Transfer Failed</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            }
            throw new Error("No ethereum object");
        }
    };

    const withdrawUSDT = async (Id, claim, claimAmount) => {
        try {
            if (ethereum) {
                setWithdrawLoading(true);
                if (chain == '0xa4b1') {
                    return withdrawUSDTArbitum(Id, claim, claimAmount)
                }
                else {
                    const PGContract = getSmartContractTestnet();
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const Val = await PGContract.withdraw(Id);
                    // console.log("withdrawUSDT", Val);
                    await Val.wait();
                    let txn_test = await provider.getTransaction(Val.hash);
                    if (txn_test) {
                        while (txn_test.blockNumber === null) {
                            // console.log("Processing...");
                            txn_test = await provider.getTransaction(Val.hash);
                        }
                        // console.log("txn_test.blockNumber: " + txn_test.blockNumber);
                        // savingInvestInfo( payment_hash)
                        let payment_hash = "https://polygonscan.com/tx/" + Val.hash;
                        // console.log("Payment link: " + payment_hash);
                        let forNumber = parseFloat(claimAmount);
                        await axios.put(`https://api.utistaking.com/api/v1/stake/claim?id=${claim}&claim=${forNumber}`, {
                        }, {
                            headers: {
                                authorization: `Bearer ${localStorage.getItem("userOfutistake")}`,
                            },
                        })
                            .then(res => {
                                // console.log(res.data);
                            })
                            .catch((e) => {
                                console.log(e);
                            })

                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = `<a href=${payment_hash} target="_any" class="link_hash  text-decoration-none text-break">Testnet Link: <span class="text-primary">${payment_hash}</span></a>`;
                        setStakeListRefetch(!stakeListRefetch);
                        return swal({
                            title: "Withdraw Successful",
                            content: wrapper,
                            icon: "success",
                            button: "Close",
                            className: "modal_class_success",
                        });
                    }
                }


            }
        }

        catch (error) {
            console.log(error);
            setWithdrawLoading(false);
            console.log("No ethereum object");
            //setRequestLoading(false);
            if (error.code === -32603) {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break  fs-5'>Insufficient funds for withdraw!</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // Swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            } else {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break  fs-5'>Withdraw Failed</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            }
            throw new Error("No ethereum object");
        }
    };
    const withdrawUSDTArbitum = async (Id, claim, claimAmount) => {
        // console.log("withdrawUSDT ID", Id);
        try {
            if (ethereum) {
                setWithdrawLoading(true);
                const PGContract = getSmartContractArbitrum();
                const provider = new ethers.providers.Web3Provider(ethereum);
                const Val = await PGContract.withdraw(Id);
                console.log("withdrawUSDT", Val);
                await Val.wait();
                let txn_test = await provider.getTransaction(Val.hash);
                if (txn_test) {
                    while (txn_test.blockNumber === null) {
                        // console.log("Processing...");
                        txn_test = await provider.getTransaction(Val.hash);
                    }
                    // console.log("txn_test.blockNumber: " + txn_test.blockNumber);
                    // savingInvestInfo( payment_hash)
                    let payment_hash = "https://arbiscan.io/tx/" + Val.hash;
                    // console.log("Payment link: " + payment_hash);
                    let forNumber = parseFloat(claimAmount);
                    await axios.put(`https://api.utistaking.com/api/v1/stake/claim?id=${claim}&claim=${forNumber}`, {
                    }, {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem("userOfutistake")}`,
                        },
                    })
                        .then(res => {
                            // console.log(res.data);
                        })
                        .catch((e) => {
                            console.log(e);
                        })

                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `<a href=${payment_hash} target="_any" class="link_hash  text-decoration-none text-break">Testnet Link: <span class="text-primary">${payment_hash}</span></a>`;
                    setStakeListRefetch(!stakeListRefetch);
                    return swal({
                        title: "Withdraw Successful",
                        content: wrapper,
                        icon: "success",
                        button: "Close",
                        className: "modal_class_success",
                    });
                }


            }
        }

        catch (error) {
            console.log(error);
            setWithdrawLoading(false);
            console.log("No ethereum object");
            //setRequestLoading(false);
            if (error.code === -32603) {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break  fs-5'>Insufficient funds for withdraw!</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // Swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            } else {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break  fs-5'>Withdraw Failed</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });


                // swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            }
            throw new Error("No ethereum object");
        }
    };

    const savingInvestInfo = async (amount, hash, ref) => {
        let walletAddress = user?.walletAddress;
        let investedAmount = amount?.toString();
        let referredBy = ref;
        // console.log("in db", walletAddress,
        //     investedAmount,
        //     referredBy)

        if (ref) {

            await axios.post(`https://api.utistaking.com/api/v1/wallet-user/affiliate?user=${user?.walletAddress}&referral=${ref}`, {
            }, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("userOfutistake")}`,
                },
            })
                .then(res => {
                    // console.log(res.data);
                })
                .catch((e) => {
                    console.log(e);
                })
        }

        await axios.post("https://api.utistaking.com/api/v1/stake", {
            walletAddress
            , investedAmount, referredBy
        }, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("userOfutistake")}`,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `
                <p class="text-success">Stake Started Successful.</p>
                <a href=${hash} target="_any" class="link_hash  text-decoration-none text-break">Mainnet Link: <span class="text-primary">${hash}</span></a>`;
                    return swal({
                        title: "Transferred",
                        content: wrapper,
                        icon: "success",
                        button: "Close",
                        className: "modal_class_success",
                    });
                }
                else {
                    swal({
                        title: "Attention",
                        content: "Transferred! But error while saving to DB!",
                        icon: "warning",
                        button: "OK",
                        // dangerMode: true,
                        className: "modal_class_success",
                    });
                }
            })
            .catch(e => {
                console.log(e);
                swal({
                    title: "Attention",
                    content: "Transferred! But error while saving to DB!",
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });
            })
            .finally(() => {
                setRequestLoading(false);
                setUserRefetch(!userRefetch);
            })

    }


    // useEffect(() => {
    //     const wrapper = document.createElement("div");
    //     wrapper.innerHTML = `
    //             <p class="text-success">Stake Started Successful.</p>
    //             <a href="https://testnet.bscscan.com/tx/0x740eef9004b6c6e930d2d76d43ddf4b429af2d5d207b338ed2f5f86cd31c642d" target="_any" class="link_hash  text-decoration-none text-break">Testnet Link: <span class="text-primary">https://testnet.bscscan.com/tx/0x740eef9004b6c6e930d2d76d43ddf4b429af2d5d207b338ed2f5f86cd31c642d</span></a>`;
    //     swal({
    //         title: "Transferred",
    //         content: wrapper,
    //         icon: "success",
    //         button: "Close",
    //         className: "modal_class_success",
    //     });
    // }, [])
    const connectToCoinbase = async () => {
        getBalanceTestnet();
        setAnchorEl(null);
        if (typeof window.ethereum == "undefined") {
            // ask the user to install the extension
            // return swal({
            //     title: "Attention",
            //     text: "Please open this website with wallet browsers",
            //     icon: "warning",
            //     button: "OK",
            //     dangerMode: true,
            //     className: "modal_class",
            // });
            if (window.innerWidth < 1000) {
                // console.log("mobile");

                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(
                        `https://metamask.app.link/dapp/http://www.utistaking.com/${val}`,
                        "_blank"
                    );
                }
                else {
                    window.open(
                        "https://metamask.app.link/dapp/http://www.utistaking.com/",
                        "_blank"
                    );
                }

            }
            else {
                // console.log("pc");
                window.open(
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                    "_blank"
                );
            }
        }
        let provider = window.ethereum;
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
            window.ethereum.providers.forEach(async (p) => {
                if (p.isCoinbaseWallet) provider = p;
            });
        }
        const chainid = await provider.request({
            method: "eth_chainId",
        });
        // console.log("This is Chain ID: ", chainid);
        setChain(() => chainid);
        // if (chainid === "0x38") {
        const accounts = await provider.request({
            method: "eth_requestAccounts",
        });
        // console.log(accounts[0]);
        setCurrentAccount(() => accounts[0]);

        await axios
            .post(`https://api.utistaking.com/api/v1/wallet-user/`, {
                walletAddress: accounts[0],
            })
            .then((res) => {
                if (res.data.user) {
                    getBalanceTestnet(chainid);
                    setUser(res.data.user);
                    setLoading(false);
                    closeCoinbaseModal();
                    localStorage.setItem("userOfutistake", res.data.token);
                    const wrapper = document.createElement("div");
                    let chainTxt = chainid == '0xa4b1' ? 'Arbitum' : chainid == '0xa4b1' ? '0x89' : ''
                    wrapper.innerHTML = `<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>Coin Base ${chainTxt}.</p>`;
                    return swal({
                        // title: "Success",
                        // text: "You have succesfully logged in with Polygon Chain.",
                        content: wrapper,
                        icon: "success",
                        button: "OK",
                        // dangerMode: true,
                        className: "modal_class_success",
                    });
                }
            });
        // } else {
        //     console.log("Please Switch to Polygon Chain");
        //     swal({
        //         title: "Attention",
        //         text: "Please change to Polygon Chain (Mainnet) before connecting.",
        //         icon: "warning",
        //         button: "OK",
        //         dangerMode: true,
        //         className: "modal_class",
        //     });
        // }
    };

    // const connectToTrustWallet = async () => {
    //     try {
    //         // Check if the user has a compatible Ethereum provider (Trust Wallet)

    //         if (typeof window.ethereum == "undefined") {
    //             // ask the user to install the extension
    //             // return swal({
    //             //     title: "Attention",
    //             //     text: "Please open this website with wallet browsers",
    //             //     icon: "warning",
    //             //     button: "OK",
    //             //     dangerMode: true,
    //             //     className: "modal_class",
    //             // });

    //             if (window.innerWidth < 1000) {
    //                 console.log("mobile");
    //                 window.open(
    //                     "https://metamask.app.link/dapp/http://usdtstake.netlify.app/",
    //                     "_blank"
    //                 );

    //             }
    //             else {
    //                 console.log("pc");
    //                 window.open(
    //                     "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
    //                     "_blank"
    //                 );
    //             }
    //             return;
    //         }

    //         if (typeof window.ethereum !== "undefined") {
    //             // Check if the user is on a mobile device
    //             // const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    //             // if (isMobileDevice) {
    //             //     // Display a message or instructions to the user about the required network
    //             //     console.log('Please switch to the Polygon Smart Chain network in your Trust Wallet');



    //             //     return;
    //             // }

    //             // Request access to the user's Ethereum accounts
    //             const accounts = await window.ethereum.request({
    //                 method: 'eth_requestAccounts',
    //             });

    //             // Create an ethers.js provider using the user's Ethereum provider
    //             const provider = new ethers.providers.Web3Provider(window.ethereum);

    //             // Set the provider to the BSC mainnet
    //             const bscMainnet = {
    //                 chainId: '0x38', // Chain ID for BSC mainnet
    //                 chainName: 'Polygon Smart Chain',
    //                 nativeCurrency: {
    //                     name: 'BNB',
    //                     symbol: 'BNB',
    //                     decimals: 18,
    //                 },
    //                 rpcUrls: ['https://bsc-dataseed1.Polygon.org/'], // BSC mainnet RPC endpoint
    //                 blockExplorerUrls: ['https://bscscan.com/'], // BSC mainnet block explorer
    //             };

    //             // Enable the BSC mainnet in the provider
    //             await provider.send('wallet_addEthereumChain', [bscMainnet]);

    //             // Get the signer (account) from the provider
    //             const signer = provider.getSigner();

    //             // Get the wallet address
    //             const address = await signer.getAddress();

    //             // You can now use the signer to interact with the BSC mainnet
    //             console.log('Connected to Trust Wallet and BSC mainnet');
    //             console.log('Selected account:', address);
    //             console.log('Network:', await provider.getNetwork());
    //             await axios
    //                 .post(`https://api.utistaking.com/api/v1/wallet-user/`, {
    //                     walletAddress: address,
    //                 })
    //                 .then((res) => {
    //                     if (res.data.user) {
    //                         setUser(res.data.user);
    //                         // getBalanceMainnet();
    //                         getBalanceTestnet();

    //                         setLoading(false);
    //                         closeWalletModal();
    //                         localStorage.setItem("userOfutistake", res.data.token);
    //                         const wrapper = document.createElement("div");
    //                         wrapper.innerHTML = `<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>Trust Wallet.</p>`;
    //                         swal({
    //                             // title: "Success",
    //                             // text: "You have succesfully logged in with Polygon Chain.",
    //                             content: wrapper,
    //                             icon: "success",
    //                             button: "OK",
    //                             // dangerMode: true,
    //                             className: "modal_class_success",
    //                         })
    //                             .then((willDelete) => {
    //                                 if (willDelete) {
    //                                     setDistination(true);
    //                                     setGoToProfile(true);
    //                                 }
    //                             })
    //                     }
    //                 });
    //             // Return the provider, signer, and address if needed
    //             return { provider, signer, address };
    //         } else {
    //             throw new Error('Trust Wallet not found or unsupported');
    //         }
    //     } catch (error) {
    //         console.error('Error connecting to Trust Wallet:', error);
    //     }
    // };

    const connectToTrustWallet = async () => {
        getBalanceTestnet();

        // Check if Trust Wallet is installed
        if (typeof window.ethereum === "undefined") {
            // ask the user to install the wallet or open the app link
            if (window.innerWidth < 1000) {
                // console.log("mobile");

                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(`https://link.trustwallet.com/open_url?coin_id=60&url=https://www.utistaking.com/${val}`, "_blank");
                }
                else {
                    window.open(`https://link.trustwallet.com/open_url?coin_id=60&url=https://www.utistaking.com/`, "_blank");
                }


            } else {
                // console.log("pc");


                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(`https://link.trustwallet.com/browser_open_url?url=https://www.utistaking.com/${val}`, "_blank");
                }
                else {
                    window.open("https://link.trustwallet.com/browser_open_url?url=https://www.utistaking.com/", "_blank");
                }

            }
        }

        let provider = null;

        if (typeof window.ethereum !== "undefined") {
            provider = window.ethereum;

            // edge case if multiple providers are available
            if (window.ethereum.providers?.length) {
                window.ethereum.providers.forEach(async (p) => {
                    if (p.isTrust) provider = p;
                });
            }

            try {
                const accounts = await provider.request({
                    method: "eth_requestAccounts",
                });

                // console.log(accounts[0]);
                setCurrentAccount(() => accounts[0]);
                setLoading(false);
                closeWalletModal();

                await provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: "0xa4b1", // Arbitrum One Mainnet chain ID
                            chainName: "Arbitrum One",
                            nativeCurrency: {
                                name: "Ethereum",
                                symbol: "ETH",
                                decimals: 18
                            },
                            rpcUrls: ["https://arb1.arbitrum.io/rpc"], // Arbitrum One Mainnet RPC URL
                            blockExplorerUrls: ["https://arbiscan.io/"] // Arbitrum One Mainnet block explorer URL
                        }
                    ],
                });
                setChain(() => "0xa4b1")
                // getBalanceTestnet("0xa4b1");
                await axios.post("https://api.utistaking.com/api/v1/wallet-user/", {
                    walletAddress: accounts[0],
                }).then((res) => {
                    if (res.data.user) {
                        setUser(res.data.user);
                        getBalanceTestnet("0xa4b1");

                        setLoading(false);
                        closeWalletModal();
                        localStorage.setItem("userOfutistake", res.data.token);

                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = "<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>Trust Wallet Arbitrum Mainnet.</p>";

                        swal({
                            content: wrapper,
                            icon: "success",
                            button: "OK",
                            className: "modal_class_success",
                        }).then((willDelete) => {
                            if (willDelete) {
                                setDistination(true);
                                setGoToProfile(true);
                            }
                        });
                    }
                });

                // console.log("Wallet provider", provider);
                return provider;
            } catch (error) {
                console.log("error ", error)
                throw new Error("User Rejected");
            }
        } else {
            throw new Error("No Wallet found");
        }
    };



    // const connectToWalletConnect = async () => {
    //     try {
    //         // Check if the user has a compatible Ethereum provider (WalletConnect)
    //         if (window.ethereum && window.ethereum.isWalletConnect) {
    //             // Create a WalletConnect provider instance
    //             const provider = new WalletConnectProvider({
    //                 rpc: {
    //                     56: 'https://bsc-dataseed1.Polygon.org/', // BSC mainnet RPC endpoint
    //                 },
    //             });

    //             // Enable the WalletConnect provider
    //             await provider.enable();

    //             // Create an ethers.js provider using the WalletConnect provider
    //             const ethersProvider = new ethers.providers.Web3Provider(provider);

    //             // Get the signer (account) from the provider
    //             const signer = ethersProvider.getSigner();

    //             // You can now use the signer to interact with the connected wallet
    //             console.log('Connected via WalletConnect');
    //             console.log('Selected account:', await signer.getAddress());
    //             console.log('Network:', await ethersProvider.getNetwork());

    //             // Return the ethers.js provider and signer if needed
    //             return { provider: ethersProvider, signer };
    //         } else {
    //             throw new Error('WalletConnect not found or unsupported');
    //         }
    //     } catch (error) {
    //         console.error('Error connecting via WalletConnect:', error);
    //     }
    // };

    // const connectToMetamask = async () => {
    //     getBalanceTestnet();
    //     if (typeof window.ethereum == "undefined") {
    //         // ask the user to install the extension
    //         // return swal({
    //         //     title: "Attention",
    //         //     text: "Please open this website with wallet browsers",
    //         //     icon: "warning",
    //         //     button: "OK",
    //         //     dangerMode: true,
    //         //     className: "modal_class",
    //         // });

    //         if (window.innerWidth < 1000) {
    //             console.log("mobile");


    //             if (sessionStorage.getItem("affiliate")) {

    //                 let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
    //                 window.open(
    //                     `https://metamask.app.link/dapp/http://www.utistaking.com/${val}`,
    //                     "_blank"
    //                 );
    //             }
    //             else {
    //                 window.open(
    //                     `https://metamask.app.link/dapp/http://www.utistaking.com/`,
    //                     "_blank"
    //                 );
    //             }

    //         }
    //         else {
    //             console.log("pc");
    //             window.open(
    //                 "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
    //                 "_blank"
    //             );
    //         }
    //     }
    //     let provider = null;
    //     if (typeof window.ethereum !== "undefined") {
    //         let provider = window.ethereum;
    //         // edge case if MM and CBW are both installed
    //         if (window.ethereum.providers?.length) {
    //             window.ethereum.providers.forEach(async (p) => {
    //                 if (p.isMetaMask) provider = p;
    //             });
    //         }
    //         try {
    //             const chainid = await provider.request({
    //                 method: "eth_chainId",
    //             });
    //             console.log("This is Chain ID: ", chainid);
    //             setChain(chainid);
    //             if (chainid === "0x89") {
    //                 const accounts = await provider.request({
    //                     method: "eth_requestAccounts",
    //                 });
    //                 console.log(accounts[0]);
    //                 setCurrentAccount(accounts[0]);
    //                 setLoading(false);
    //                 closeWalletModal();

    //                 await axios
    //                     .post(`https://api.utistaking.com/api/v1/wallet-user/`, {
    //                         walletAddress: accounts[0],
    //                     })
    //                     .then((res) => {
    //                         if (res.data.user) {
    //                             setUser(res.data.user);
    //                             // getBalanceMainnet();
    //                             getBalanceTestnet();

    //                             setLoading(false);
    //                             closeWalletModal();
    //                             localStorage.setItem("userOfutistake", res.data.token);
    //                             const wrapper = document.createElement("div");
    //                             wrapper.innerHTML = `<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>Polygon Mainnet.</p>`;
    //                             return swal({
    //                                 // title: "Success",
    //                                 // text: "You have succesfully logged in with Polygon Chain.",
    //                                 content: wrapper,
    //                                 icon: "success",
    //                                 button: "OK",
    //                                 // dangerMode: true,
    //                                 className: "modal_class_success",
    //                             })
    //                                 .then((willDelete) => {
    //                                     if (willDelete) {
    //                                         setDistination(true);
    //                                         setGoToProfile(true);
    //                                     }
    //                                 })
    //                         }
    //                     });
    //             } else {
    //                 console.log("Please Switch to Polygon Mainnet");
    //                 swal({
    //                     title: "Attention",
    //                     text: "Please change to Polygon Mainnet before connecting.",
    //                     icon: "warning",
    //                     button: "OK",
    //                     dangerMode: true,
    //                     className: "modal_class",
    //                 });
    //             }
    //         } catch (error) {
    //             throw new Error("User Rejected");
    //         }
    //     } else {
    //         throw new Error("No MetaMask Wallet found");
    //     }
    //     console.log("MetaMask provider", provider);
    //     return provider;
    // };
    const connectToMetamask = async () => {
        getBalanceTestnet();
        if (typeof window.ethereum == "undefined") {
            // ask the user to install the extension
            // return swal({
            //     title: "Attention",
            //     text: "Please open this website with wallet browsers",
            //     icon: "warning",
            //     button: "OK",
            //     dangerMode: true,
            //     className: "modal_class",
            // });

            if (window.innerWidth < 1000) {
                // console.log("mobile");


                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(
                        `https://metamask.app.link/dapp/http://www.utistaking.com/${val}`,
                        "_blank"
                    );
                }
                else {
                    window.open(
                        `https://metamask.app.link/dapp/http://www.utistaking.com/`,
                        "_blank"
                    );
                }

            }
            else {
                // console.log("pc");
                window.open(
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                    "_blank"
                );
            }
        }
        let provider = null;
        if (typeof window.ethereum !== "undefined") {
            let provider = window.ethereum;
            // edge case if MM and CBW are both installed
            if (window.ethereum.providers?.length) {
                window.ethereum.providers.forEach(async (p) => {
                    if (p.isMetaMask) provider = p;
                });
            }
            try {
                const chainid = await provider.request({
                    method: "eth_chainId",
                });
                // console.log("This is Chain ID: ", chainid);
                setChain(() => chainid);
                if (chainid === "0xa4b1" || chainid === "0x89") {
                    const accounts = await provider.request({
                        method: "eth_requestAccounts",
                    });
                    // console.log(accounts[0]);
                    setCurrentAccount(() => accounts[0]);
                    setLoading(false);
                    closeWalletModal();

                    await axios
                        .post(`https://api.utistaking.com/api/v1/wallet-user`, {
                            walletAddress: accounts[0],
                        })
                        .then((res) => {
                            if (res.data.user) {
                                setUser(res.data.user);
                                // getBalanceMainnet();
                                getBalanceTestnet(chainid);

                                setLoading(false);
                                closeWalletModal();
                                localStorage.setItem("userOfutistake", res.data.token);
                                const wrapper = document.createElement("div");
                                let networkID = chainid === "0xa4b1" ? "Arbitrum" : chainid === "0x89" ? "Polygon" : "";
                                wrapper.innerHTML = `<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>${networkID}.</p>`;
                                return swal({
                                    // title: "Success",
                                    // text: "You have succesfully logged in with Polygon Chain.",
                                    content: wrapper,
                                    icon: "success",
                                    button: "OK",
                                    // dangerMode: true,
                                    className: "modal_class_success",
                                })
                                    .then((willDelete) => {
                                        if (willDelete) {
                                            setDistination(true);
                                            setGoToProfile(true);
                                        }
                                    })
                            }
                        });
                } else {
                    // console.log("Please Switch to Polygon Mainnet or Arbitrum Mainnet");
                    swal({
                        title: "Attention",
                        text: "Please change to Polygon Mainnet or Arbitrum Mainnet before connecting.",
                        icon: "warning",
                        button: "OK",
                        dangerMode: true,
                        className: "modal_class",
                    });
                }
            } catch (error) {
                throw new Error("User Rejected");
            }
        } else {
            throw new Error("No MetaMask Wallet found");
        }
        // console.log("MetaMask provider", provider);
        return provider;
    };

    const setID = async () => {
        try {
            if (ethereum) {
                console.log("No ethereum object");
            }
        } catch (error) {
            console.log(error);
            throw new Error("No ethereum object");
        }
    };

    useEffect(() => {
        //https://api.utistaking.com/api/v1/wallet-user/api/v1/wallet-user/all
        setLoading(true);
        axios.get('https://api.utistaking.com/api/v1/wallet-user/all')
            .then((res) => {
                setAllUsers(res.data.result)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        checkIfWalletIsConnect();
    }, []);

    useEffect(() => {
        const getTheCurrent = async () => {
            // console.log("usering.....1");
            setWalletLoading(true);
            setLoading(true);
            //https://api.utistaking.com/api/v1/wallet-user/
            await axios
                .get(`https://api.utistaking.com/api/v1/wallet-user/`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("userOfutistake")}`,
                    },
                })
                .then((res) => {
                    setUser(res.data);
                    let finding = res.data?.totalReferred;
                    if (finding <= 2) { setLevel(() => "1st"); setRoi(0.1); setPrcntge(0.999); }
                    else if (finding >= 3 && finding <= 4) { setLevel(() => "2nd"); setRoi(0.2); setPrcntge(0.998); }
                    else if (finding >= 5 && finding <= 6) { setLevel(() => "3rd"); setRoi(0.3); setPrcntge(0.997); }
                    else if (finding >= 7 && finding <= 9) { setLevel(() => "4th"); setRoi(0.4); setPrcntge(0.996); }
                    else if (finding >= 10) { setLevel(() => "5th"); setRoi(0.5); setPrcntge(0.995); };
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                    setWalletLoading(false);
                    // console.log("usering.....2");
                });
            // setUserRefetch(false);
        }
        if (currentAccount || localStorage.getItem("userOfutistake")) {
            getTheCurrent();
        }
    }, [currentAccount, userRefetch, localStorage.getItem("userOfutistake")]);

    useEffect(() => {
        if (requestLoading) {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>UTI Transaction Pending...<b></p> `;
            swal({
                content: wrapper,
                button: false,
                className: "modal_class_success",
            });
        }
    }, [requestLoading]);

    useEffect(() => {
        if (withdrawLoading) {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>Withdraw Processing...<b></p> `;
            swal({
                content: wrapper,
                button: false,
                className: "modal_class_success",
            });
        }
    }, [withdrawLoading]);


    return (
        <USDStakeContext.Provider
            value={{
                connectWallet,
                currentAccount,
                loginModal,
                setLoginModal,
                requestLoading,
                setRequestLoading,
                walletModal,
                user,
                setUser,
                logOut,
                loading,
                Id,
                setID,
                setUserRefetch,
                userRefetch,
                chain,
                pageLoading,
                payAmount,
                setPayAmount,
                metamaskBalance,
                setWalletModal,
                coinbaseModal,
                metamaskBalanceLoading,
                getBalanceTestnet,
                closeWalletModal,
                closeCoinbaseModal,
                openWalletModal,
                openCoinbaseModal,
                openLoginModal,
                closeLoginModal,
                destination,
                setDistination,
                setMetamaskBalanceLoading,
                connectToCoinbase,
                connectToMetamask,
                connectToTrustWallet,
                signBuyFunction,
                goToProfile,
                setGoToProfile,
                allUsers,
                setAllUsers,
                anchorEl,
                setAnchorEl,
                openWalletModalWarning,
                closeWalletModalWarning,
                walletWarning,
                payUSDT,
                withdrawUSDT,
                level,
                roi,
                prcntge,
                fetchData,
                contractData,
                stakeListRefetch,
                setStakeListRefetch,
                walletLoading,

            }}
        >
            {children}
        </USDStakeContext.Provider>
    );
}